<template>
<div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
    <div class="row justify-content-around">
        <div class="card-body mb-3 col-12">
            <router-link :to="{name: 'SyllabusPointTargetCreateCoursesAdmin', params: {idCourse: paramsId, idSyllabus: idSyllabus, idPoint: idPoint}}" class="float-right btn btn-lg btn-current"><i class="fas fa-plus"></i></router-link>
            <h2 class="fw-400 font-lg"><b>Unit Syllabus - {{point.content}}</b></h2>
        </div>
        <div v-if="!isLoad" class="col-12">
            <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter" @keyup="searchTable()">
        </div>
        <div class="card-body w-100">
            <div class="row">
                <div v-if="!isLoad" class="col-12 text-center py-3">
                    <div class="table-responsive mw-100 pr-2 ml-0">
                        <table class="table table-hover table-bordered" id="custom-table">
                            <thead class="">
                            <tr>
                                <th class="border-0 bg-current text-white p-3" scope="col" colspan="2">No</th>
                                <th class="border-0 bg-current text-white p-3" scope="col">Target</th>
                                <th class="border-0 bg-current text-white p-3" scope="col">Action</th>
                            </tr>
                            </thead>
                            <draggable v-model="point.target" tag="tbody" handle=".handle" :disabled="!drag" @end="reOrder()">
                            <tr v-for="(row, index) in point.target" :key="index">
                                <td><a href="javascript:void(0)"><i class="fas fa-bars handle"></i></a></td>
                                <td>{{ index+1 }}</td>
                                <td>{{ row.content }}</td>
                                <td>
                                    <router-link :to="{name: 'SyllabusPointTargetEditCoursesAdmin', params: {idCourse: paramsId, idSyllabus: idSyllabus, idPoint: idPoint, idTarget: row.id}}" class="btn btn-warning text-white btn-sm mr-2"><i class="fas fa-edit"></i></router-link>
                                    <button @click="deleteAlert(row.id)" class="btn btn-danger btn-sm"><i class="fas fa-trash"></i></button>
                                </td>
                            </tr>
                            </draggable>
                        </table>
                    </div>
                </div>
                <div v-else class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
export default {
    name: 'Syllabus',
    components: {
        draggable
    },
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            idSyllabus: this.$route.params.idSyllabus,
            idPoint: this.$route.params.idPoint,
            isLoad: true,
            drag: true,
            point: [],
            tableFilter: '',
        }
    },
    created(){
        this.getPoint()
    },
    methods:{
        async reOrder() {
            this.drag = false
            var data ={
                target: this.point.target,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/syllabus/point/target/reorder`, data,  {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Target',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.drag = true;
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Target',
                    text: 'Failed Reorder!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.getPoint();
                this.drag = true;
            })
        },
        searchTable(){
            var input, table, tr, td, i, j, txtValue, show;
            input = this.tableFilter.toLowerCase();
            table = document.getElementById("custom-table");
            tr = table.querySelectorAll('tbody tr')
            for (i = 0; i < tr.length; i++) {
                show = false
                td = tr[i].getElementsByTagName("td");
                if (td.length > 0) {
                    for(j = 0; j < td.length; j++) {
                        txtValue = td[j].textContent || td[j].innerText;
                        if (txtValue.toLowerCase().indexOf(input) > -1) {
                            show = true
                        }
                    }
                }
                tr[i].style.display = !show ? "none" : "";
            }

        },
        async getPoint(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/syllabus/point/detail?id=${this.idPoint}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.isLoad = false
                this.point = res.data
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus Unit',
                    text: 'Failed Load Syllabus!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    this.deletePoint(id)
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.getPoint()
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Syllabus',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deletePoint(point_id){
            var data = {
                id: point_id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/syllabus/point/target`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.status == 200){
                    this.$swal({
                        toast: true,
                        title: 'Success Delete Syllabus Point !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    
                }
            })
        }
    }
}
</script>

<style>

</style>